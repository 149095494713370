import React from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor"
import './auction-ck-editor.css';

const AuctionCkEditor = (props) => {

    const {handleMessageChange, isTabletOrMobile, showToolbar = false, clazzName = ''} = props

    const handleEditorChange = (event, editor) => {
        handleMessageChange(editor.getData())
    };

    const itemsArr = [
        'bold',
        'italic',
        'underline',
        '|',
        'fontColor',
        'fontSize',
        'fontFamily',
        '|',
        'link',
        'alignment',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'insertImageViaUrl',
        'blockQuote',
        'mediaEmbed',
        'spoiler',
        'undo',
        'redo',
        '|',

    ];
    const items = (showToolbar === false) ? [] : (isTabletOrMobile) ? [] : itemsArr;

    const editorConfig = {
        toolbar: {
            items: items
        },
        alignment: {
            options: ['left', 'right', 'center', "justify"]
        },
        fontSize: {
            options: [
                10, 12, 14, 16, 18, 20, 22, 24, 26
            ],
            supportAllValues: true
        },

        htmlSupport: {
            allow: [
                {
                    name: 'font',
                    attributes: {
                        size: true
                    }
                },
                {
                    name: 'p',
                    attributes: {
                        style: true
                    }
                }
            ]
        },

    };

    return (
        <div className={`custom-editor ${clazzName}`}>
            <CKEditor
                editor={Editor}
                config={editorConfig}
                data={props.message}
                onChange={handleEditorChange}
            />
        </div>
    );
};

export default AuctionCkEditor;