export const selectModalStates = (state) => {
    const {
        modal: { modalShow: modalInlineShow },
        modalPrefix: { modalShow: modalPrefixShow },
        modalPromotion: { modalShow: modalPromotionShow },
        modalCreateLot: { showModal: modalCreateLotShow },
        sortingSetting: { modalShow: modalSortingSettingShow },
        imageGalleryReducer: { modalShow: modalImageGalleryShow },
        modalSubscriptionPrefix: { modalShow: modalFollowPrefixShow },
        modalSubscriptionCategory: { modalShow: modalFollowCategoryShow },
        categorySettingsReducer: { modalShow: modalCategorySettingsShow },
        modalPenaltyForMessage: { modalShow: modalPenaltyForMessageShow },
    } = state;

    return {
        modalInlineShow,
        modalPrefixShow,
        modalCreateLotShow,
        modalPromotionShow,
        modalImageGalleryShow,
        modalFollowPrefixShow,
        modalSortingSettingShow,
        modalFollowCategoryShow,
        modalCategorySettingsShow,
        modalPenaltyForMessageShow,
    };
};