import React, {useCallback, useMemo} from "react";
import { getLinkData, getParams, getUrl } from "../../../../redux/lots/action";
import { removeFilter } from "../../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {useDispatch, useSelector} from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import queryString from "query-string";
import GlobalHelper from "../../../../helpers/global-helper";
import includes from "lodash/includes";
import find from "lodash/find";

const CategoryLinkComponent = React.memo((props) => {
    const { item, className = '' } = props;
    const { category_description, auction_category_id } = item;
    const {user_sort_data} = useSelector((state) => state.lots);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let linkParams = dispatch(getParams());
    const { user_lots_type } = linkParams;

    const userSortParams = useMemo(()=> {
        const {direction, sort_order} = find(user_sort_data, {user_lots_type});
        return {direction, order:sort_order}
    },[user_lots_type, user_sort_data])

    const getLinkParams = useCallback(() => {
        delete linkParams.auction_category_id;
        linkParams.page = 1;
        const urlParams = queryString.parse(dispatch(getLinkData(linkParams)));

        if (userSortParams.direction === urlParams.direction) {
            delete urlParams.direction;
        }
        if (userSortParams.order === urlParams.order) {
            delete urlParams.order;
        }
        return queryString.stringify(urlParams)
    }, [dispatch, linkParams, user_lots_type]);

    const setParameters = useCallback(() => {
        if (linkParams.prefix_id !== undefined) {
            dispatch(removeFilter('prefix_id'));
            const queryLink = dispatch(getLinkData({ page: 1 }));
            navigate(`/?${queryLink}`);
            linkParams = dispatch(getParams());
        }

        if (item.auction_category_id === 56 && user_lots_type !== 'archive_lots') {
            window.location.href = item.category_link
        }

    }, [dispatch, linkParams, navigate]);

    if (includes(['auction_lots', 'archive_lots'], user_lots_type)) {
        const { link } = dispatch(getUrl());
        const l = (user_lots_type === 'auction_lots') ? `/auctions/` : link;

        const paramsTo = {
            pathname: `${l}${GlobalHelper.getCategoryVariableReference(item)}`,
            search: `?${getLinkParams()}`
        };

        return (
            <Link onClick={setParameters} className={className} to={paramsTo}>
                {props.children}
            </Link>
        );
    }

    return (
        <a
            href={`/auctions/${GlobalHelper.getCategoryVariableReference(item)}`}
            onClick={(e) => {
                e.preventDefault();
                const queryLink = dispatch(getLinkData({ auction_category_id, page: 1 }));
                navigate(`?${queryLink}`);
            }}
            title={category_description}
        >
            {props.children}
        </a>
    );
});

export default CategoryLinkComponent;