import React, {lazy} from 'react';
import {useSelector} from 'react-redux';
import ModalInlineModCheck from "./modal-inline-mod-check";
import ModalImageGallery from "./modal-image-gallery";
import ModalCreateLot from "./modal-create-lot";
import {createPortal} from "react-dom";
import { selectModalStates } from './modalSelectors';

// ������� �������������� ��������� ����
// const ModalImageGallery = lazy(() => import('../modals/modal-image-gallery'));
// const ModalInlineModCheck = lazy(() => import('../modals/modal-inline-mod-check'));
const ModalSortingSettings = lazy(() => import('./modal-sorting-settings'));
const ModalPrefix = lazy(() => import('./modal-prefix'));
const ModalSubscriptionPrefix = lazy(() => import('./modal-subscription/modal-subscription-prefix/modal-subscription-prefix'));
const ModalSubscriptionCategory = lazy(() => import('./modal-subscription/modal-subscription-category/modal-subscription-category'));
const ModalPromotionLots = lazy(() => import('./modal-promotion-lots'));
const ModalCategorySettings = lazy(() => import('./modal-category-settings/modal-category-settings'));
const ModalAddPenaltyForMessage = lazy(() => import('./modal-add-penalty-for-message/modal-add-penalty-for-message'));


const modalContainer = document.getElementById('modal-root');

const Modals = () => {

    const {
        modalInlineShow,
        modalFollowPrefixShow,
        modalSortingSettingShow,
        modalCreateLotShow,
        modalPrefixShow,
        modalFollowCategoryShow,
        modalPromotionShow,
        modalCategorySettingsShow,
        modalImageGalleryShow,
        modalPenaltyForMessageShow,
    } = useSelector(selectModalStates);

    return (
        createPortal(
            <>
                {modalPrefixShow && <ModalPrefix/>}
                {modalCreateLotShow && <ModalCreateLot/>}
                {modalInlineShow && <ModalInlineModCheck/>}
                {modalPromotionShow && <ModalPromotionLots/>}
                {modalImageGalleryShow && <ModalImageGallery/>}
                {modalSortingSettingShow && <ModalSortingSettings/>}
                {modalFollowPrefixShow && <ModalSubscriptionPrefix/>}
                {modalCategorySettingsShow && <ModalCategorySettings/>}
                {modalFollowCategoryShow && <ModalSubscriptionCategory/>}
                {modalPenaltyForMessageShow && <ModalAddPenaltyForMessage/>}
            </>,
            modalContainer
        )
    );
}

export default Modals;
