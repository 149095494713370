import React, {useContext} from 'react';
import {truncate} from "lodash/string"
import './lot-short-description.css'
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import parse from "html-react-parser";

const LotShortDescription = () => {
    const {lotData:{message}} = useContext(lotContext);
    return (
        <div data-action className="shortDescription">
            {truncate(parse(message), {'length': 150, 'separator': '...'})}
        </div>
    );
}
export default LotShortDescription;